<template>
  <Fragment>
    <v-dialog v-model="modal" persistent>
      <v-card :loading="cargando">
        <v-card-title>
          <h1>Formulario de registro</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="formulario.id_pais"
                :items="paises"
                label="Seleccione el pais"
                item-text="nombre"
                item-value="id"
                clearable
                outlined
                :error-messages="id_paisErrors"
                @input="$v.formulario.id_pais.$touch()"
                @blur="$v.formulario.id_pais.$touch()"
                :loading="cargando"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                clearable
                v-model="formulario.nombre_compra"
                label="Nombre/Denominación de la Contratación"
                @input="$v.formulario.nombre_compra.$touch()"
                @blur="$v.formulario.nombre_compra.$touch()"
                :error-messages="nombre_compraErrors"
                :loading="cargando"
              />
            </v-col>
            <v-col cols="12" md="6">
              <vc-date-picker
                v-model="formulario.fecha_contratacion"
                mode="date"
                locale="es"
                :popover="config_calendar"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                    label="Fecha de contratación/compra *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    :value="
                      formulario.fecha_contratacion
                        ? moment(formulario.fecha_contratacion).format(
                            'DD/MM/YYYY'
                          )
                        : ''
                    "
                    v-on="inputEvents"
                    outlined
                    :error-messages="fecha_contratacionErrors"
                    @input="$v.formulario.fecha_contratacion.$touch()"
                    @blur="$v.formulario.fecha_contratacion.$touch()"
                    :loading="cargando"
                  />
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formulario.monto_contratado"
                clearable
                outlined
                prepend-inner-icon="mdi-currency-usd"
                label="Monto contratado en dólares"
                :error-messages="monto_contratadoErrors"
                @input="$v.formulario.monto_contratado.$touch()"
                @blur="$v.formulario.monto_contratado.$touch()"
                :loading="cargando"
                type="number"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-alert
                outlined
                type="info"
                elevation="2"
                border="left"
                colored-border
                icon="mdi-information"
              >
                <strong>Nota:</strong> El monto contratado en dólares debe ser
                el equivalente al monto contratado en la moneda local, en la
                fecha de la compra o contratación
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="formulario.id_divisa"
                :items="divisas"
                label="Seleccione la divisa"
                item-text="pais"
                item-value="id"
                clearable
                outlined
                :error-messages="divisaError"
                @input="$v.formulario.id_divisa.$touch()"
                @blur="$v.formulario.id_divisa.$touch()"
                :loading="cargando"
              >
                <template v-slot:selection="{ item }">
                  {{ `${item.pais} - ${item.divisa} - ${item.codigo}` }}
                </template>
                <template v-slot:item="{ item }">
                  {{ `${item.pais} - ${item.divisa} - ${item.codigo}` }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formulario.monto_divisa"
                clearable
                outlined
                prepend-inner-icon="mdi-currency-usd"
                :label="`Monto contratado en la moneda local ${divisa_pais}`"
                :error-messages="montoconvertidoError"
                @input="$v.formulario.monto_divisa.$touch()"
                @blur="$v.formulario.monto_divisa.$touch()"
                :loading="cargando"
                :disabled="!formulario.id_divisa"
                type="number"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                outlined
                clearable
                v-model="formulario.proveedor"
                label="Proveedor"
                @input="$v.formulario.proveedor.$touch()"
                @blur="$v.formulario.proveedor.$touch()"
                :error-messages="proveedorErrors"
                :loading="cargando"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                clearable
                outlined
                label="Estado/Ciudad"
                v-model="formulario.estado"
                @input="$v.formulario.estado.$touch()"
                @blur="$v.formulario.estado.$touch()"
                :error-messages="EstadoErrors"
                :loading="cargando"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                clearable
                v-model="formulario.contratos"
                label="Adjuntos"
                outlined
                @input="$v.formulario.contratos.$touch()"
                @blur="$v.formulario.contratos.$touch()"
                :error-messages="contratosErrors"
                accept="application/pdf"
                :loading="cargando"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                clearable
                outlined
                v-model="formulario.insumos_adquiridos"
                label="Insumos adquiridos"
                @input="$v.formulario.insumos_adquiridos.$touch()"
                @blur="$v.formulario.insumos_adquiridos.$touch()"
                :error-messages="insumos_adquiridosErrors"
                :loading="cargando"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cerrarFormulario" color="primary">Cerrar</v-btn>
          <v-btn @click="enviar" color="secondary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

function esNumeroValido(input) {
  const regex = /^\d+(\.\d+)?$/;
  return regex.test(input);
}

export default {
  name: "formularioDeRegistro",
  emits: ["on-visibility-change"],
  methods: {
    ...mapActions("compraExterior", [
      "guardarRegistro",
      "getPaises",
      "resetForm",
      "setCargandoTrue",
      "getDivisas",
    ]),
    async enviar() {
      this.$v.formulario.$touch();
      if (this.$v.formulario.$invalid) return;
      await this.guardarRegistro(() => this.$emit("cerrar"));
      this.$v.formulario.$reset();
    },
    cerrarFormulario() {
      this.setCargandoTrue();
      this.$emit("cerrar");
      this.$v.formulario.$reset();
      this.resetForm();
    },
  },
  validations: {
    formulario: {
      id_pais: {
        required,
      },
      nombre_compra: {
        required,
      },
      insumos_adquiridos: {
        required,
      },
      monto_contratado: {
        required,
        numeric: esNumeroValido,
      },
      fecha_contratacion: {
        required,
      },
      contratos: {
        required,
      },
      proveedor: {
        required,
      },
      id_divisa: {
        required,
      },
      monto_divisa: {
        required,
        numeric: esNumeroValido,
      },
      estado: {
        required,
      },
    },
  },
  components: { Fragment },
  props: {
    modal: Boolean,
  },
  computed: {
    ...mapState("compraExterior", [
      "formulario",
      "paises",
      "cargando",
      "divisas",
    ]),
    fecha_contratacionErrors() {
      const errors = [];
      if (!this.$v.formulario.fecha_contratacion.$dirty) return errors;
      !this.$v.formulario.fecha_contratacion.required &&
        errors.push("La fecha de contratación es requerida");
      return errors;
    },
    contratosErrors() {
      const errors = [];
      if (!this.$v.formulario.contratos.$dirty) return errors;
      !this.$v.formulario.contratos.required &&
        errors.push("Los contratos son requeridos");
      return errors;
    },
    monto_contratadoErrors() {
      const errors = [];
      if (!this.$v.formulario.monto_contratado.$dirty) return errors;
      !this.$v.formulario.monto_contratado.required &&
        errors.push("El monto contratado es requerido");
      !this.$v.formulario.monto_contratado.numeric &&
        errors.push("El monto contratado debe ser un número");
      return errors;
    },
    insumos_adquiridosErrors() {
      const errors = [];
      if (!this.$v.formulario.insumos_adquiridos.$dirty) return errors;
      !this.$v.formulario.insumos_adquiridos.required &&
        errors.push("Los insumos adquiridos son requeridos");
      return errors;
    },
    nombre_compraErrors() {
      const errors = [];
      if (!this.$v.formulario.nombre_compra.$dirty) return errors;
      !this.$v.formulario.nombre_compra.required &&
        errors.push("La denominación de la contratación es requerida");
      return errors;
    },
    id_paisErrors() {
      const errors = [];
      if (!this.$v.formulario.id_pais.$dirty) return errors;
      !this.$v.formulario.id_pais.required &&
        errors.push("El pais es requerido");
      return errors;
    },
    proveedorErrors() {
      const errors = [];
      if (!this.$v.formulario.proveedor.$dirty) return errors;
      !this.$v.formulario.proveedor.required &&
        errors.push("El proveedor es requerido");
      return errors;
    },
    divisa_pais() {
      const filtro = this.divisas.filter(
        (divisa) => divisa.id === this.formulario.id_divisa
      );
      return filtro.length > 0 ? `(${filtro[0].divisa})` : "";
    },
    divisaError() {
      const errors = [];
      if (!this.$v.formulario.id_divisa.$dirty) return errors;
      !this.$v.formulario.id_divisa.required &&
        errors.push("La divisa es requerida");
      return errors;
    },
    montoconvertidoError() {
      const errors = [];
      if (!this.$v.formulario.monto_divisa.$dirty) return errors;
      !this.$v.formulario.monto_divisa.required &&
        errors.push("El monto convertido es requerido");
      !this.$v.formulario.monto_divisa.numeric &&
        errors.push("El monto convertido debe ser un número");
      return errors;
    },
    EstadoErrors() {
      const errors = [];
      if (!this.$v.formulario.estado.$dirty) return errors;
      !this.$v.formulario.estado.required &&
        errors.push("El estado es requerido");
      return errors;
    },
  },
  data() {
    return {
      config_calendar: {
        visibility: "click",
      },
    };
  },
  async created() {
    await this.getPaises();
    await this.getDivisas();
  },
};
</script>
