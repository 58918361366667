<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title class="secondary white--text">
          <h1>Compras en el Exterior</h1>
        </v-card-title>

          
        <v-card-text class="v-list-group--active">
        <v-btn v-if="haveRole('compra_exterior_reg_informe_crear')" 
          class="mt-4" @click="modal = true" color="secondary">
          Agregar registro
        </v-btn>
        
        <v-row class="mt-4">
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-model="filters.denominacion"
              label="Denominación de la contratación"
              outlined
              clearable
              @input="debouncedActualizarFiltro"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              v-model="filters.id_pais"
              label="Seleccione un país"
              :items="paises"
              item-value="id"
              item-text="nombre"
              outlined
              clearable
              @change="actualizarFiltro"
            />
          </v-col>
          <v-col cols="12" sm="12" md="4"
            v-if="haveRole('compra_exterior_reg_informe_buscar_institucion')"
          >
            <v-autocomplete
              v-model="filters.id_institucion"
              label="Filtrar por institución"
              :items="instituciones"
              item-value="id"
              item-text="nombre"
              clearable
              outlined
              @change="actualizarFiltro"
            />
          </v-col>
      </v-row>

          <listado />
          <formularioDeRegistro :modal="modal" @cerrar="cerrarAction" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import listado from "@/views/CompraExterior/components/listado.vue";
import formularioDeRegistro from "@/views/CompraExterior/components/formularioDeRegistro.vue";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "mainCompraExterior",
  components: { listado, formularioDeRegistro },
  data() {
    return {
      modal: false,
      filters: {
        id_institucion: null,
        id_pais: null,
        denominacion: null,
      },
      timer: null,
    };
  },
  methods: {
    ...mapActions("compraExterior", [
      "getListadoRegistros",
      "getInstituciones",
      "setFiltroInstitucion",
      "setFiltroPais",
      "setFiltroDenominacion",
    ]),
    async cerrarAction() {
      this.modal = false;
      await this.getListadoRegistros();
    },
    async actualizarFiltro() {
      await this.setFiltroInstitucion(this.filters?.id_institucion);
      await this.setFiltroPais(this.filters?.id_pais);
      await this.setFiltroDenominacion(this.filters?.denominacion);
      await this.getListadoRegistros();
    },
  },
  computed: {
    ...mapState("compraExterior", ["instituciones", "paises"]),
  },
  async created() {
    this.debouncedActualizarFiltro= debounce(this.actualizarFiltro, 600);
    if (this.haveRole("compra_exterior_reg_informe_buscar_institucion"))
      await this.getInstituciones();
  },
};
</script>
